
jQuery.fn.preventDoubleSubmit = function() {
  $(this).on('submit', function(evt) {
    var $form = $(this);
    if ($form.data('submitted') === true) {
      evt.preventDefault();
    } else {
      $form.data('submitted', true);
    }
  })

  return this;
}

jQuery.fn.addRadioReset = function () {
	return this.each(function() {
		var $radioButtons = jQuery(this);
		var resetButton = jQuery("<a href='#'>Clear</a>").click(function(ev) {
			ev.preventDefault();
		        $radioButtons.find('input:radio').prop('checked',false);
			if( typeof runConstraints == 'function' ){ runConstraints(); }

		});
		$radioButtons.append(resetButton[0]);
	})
}

export function initializePage( args ){

	$( '.table-datatable' ).each( function( index, element ){
		$( element ).dataTable( {
			ordering : true,
			searching : true,
			paging : true,
			pageLength : 10,
			lengthMenu : [ [ 10, 25, 50, -1 ], [ 10, 25, 50, "All" ] ],
			order : [ [ 0, "asc" ] ],
			autoWidth : false
		} );
	} );

	$( '.dateinput' ).each( function( index, element ){
		$( element ).datepicker( {
			dateFormat : 'mm/dd/yy'
		} );
		if ( $( element ).hasClass( 'default-now' ) && $( element ).val().trim() == '' ) {
			var d = new Date();
			var curr_date = d.getDate();
			var curr_month = d.getMonth() + 1; // months are zero based
			var curr_year = d.getFullYear();
			$( element ).val( ( curr_month < 10 ? "0" : "" ) + curr_month + "/" + ( curr_date < 10 ? "0" : "" ) + curr_date + "/" + curr_year );
		}
	} );

	$( '.dateinput_nobutton' ).each( function( index, element ){
		$( element ).datepicker( {
			dateFormat : 'mm/dd/yy',
			changeMonth : true,
			changeYear : true,
			yearRange : 'c-10:c+0'
		} );
		if ( $( element ).hasClass( 'default-now' ) && $( element ).val().trim() == '' ) {
			var d = new Date();
			var curr_date = d.getDate();
			var curr_month = d.getMonth() + 1; // months are zero based
			var curr_year = d.getFullYear();
			$( element ).val( ( curr_month < 10 ? "0" : "" ) + curr_month + "/" + ( curr_date < 10 ? "0" : "" ) + curr_date + "/" + curr_year );
		}
	} );

	$("form[data-prevent-double-submit='true']").preventDoubleSubmit();
	$(".radio-buttons[data-add-reset-button='true']").addRadioReset();
	tinymce.init( {
		setup : function( ed ){
			// fix for chrome because it breaks when value contains &lt;someValue
			var $el = $( '#' + ed.id );
			var val = $el.val();
			if( val ){
				val = val.replace( /&lt;/g, "&lt; " );
				val = val.replace( /&gt;/g, "&gt; " );
				$el.val( val );
			}
		},
		selector : "textarea.basic_tinymce",
		force_br_newlines : false,
		force_p_newlines : false,
		forced_root_block : '',
		plugins : [ "advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime media table contextmenu paste" // moxiemanager 
		],
		toolbar : "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image"
	} );

	tinymce.init( {
		setup : function( ed ){
			// fix for chrome because it breaks when value contains &lt;someValue
			var $el = $( '#' + ed.id );
			var val = $el.val();
			if( val ){
				val = val.replace( /&lt;/g, "&lt; " );
				val = val.replace( /&gt;/g, "&gt; " );
				$el.val( val );
			}
		},
		selector : "textarea.advanced_tinymce",
		force_br_newlines : false,
		force_p_newlines : false,
		forced_root_block : '',
		plugins : [ "advlist autolink lists link image charmap print preview hr anchor pagebreak", "searchreplace wordcount visualblocks visualchars code fullscreen", "insertdatetime media nonbreaking save table contextmenu directionality", "emoticons template paste textcolor spellchecker" // moxiemanager 
		],
		toolbar1 : "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
		toolbar2 : "print preview media | forecolor backcolor emoticons | spellchecker",
		image_advtab : true,
		spellchecker_callback : function( method, text, success, failure ){
			tinymce.util.JSONRequest.sendRPC( {
				url : args.mce_spellcheck_url,
				method : "spellcheck",
				params : {
					lang : this.getLanguage(),
					words : text.match( this.getWordCharPattern() )
				},
				success : function( result ){
					success( result );
				},
				error : function( error, xhr ){
					failure( "Spellcheck Error : " + xhr.status );
				}
			} );
		},
		convert_urls : false
	} );

	tinymce.init( {
		setup : function( ed ){
			// fix for chrome because it breaks when value contains &lt;someValue
			var $el = $( '#' + ed.id );
			var val = $el.val();
			if( val ){
				val = val.replace( /&lt;/g, "&lt; " );
				val = val.replace( /&gt;/g, "&gt; " );
				$el.val( val );
			}
		},
		selector : "textarea.classic_tinymce",
		force_br_newlines : false,
		force_p_newlines : false,
		forced_root_block : '',
		plugins : [ "advlist autolink autosave link image lists charmap print preview hr anchor pagebreak spellchecker", "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking", "table contextmenu directionality emoticons template textcolor paste fullpage textcolor" ],
		toolbar1 : "newdocument fullpage | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | styleselect formatselect fontselect fontsizeselect",
		toolbar2 : "cut copy paste | searchreplace | bullist numlist | outdent indent blockquote | undo redo | link unlink anchor image media code | inserttime preview | forecolor backcolor | spellchecker",
		toolbar3 : "table | hr removeformat | subscript superscript | charmap emoticons | print fullscreen | ltr rtl | spellchecker | visualchars visualblocks nonbreaking template pagebreak restoredraft",
		menubar : false,
		toolbar_items_size : 'small',
		style_formats : [ {
			title : 'Bold text',
			inline : 'b'
		}, {
			title : 'Red text',
			inline : 'span',
			styles : {
				color : '#ff0000'
			}
		}, {
			title : 'Red header',
			block : 'h1',
			styles : {
				color : '#ff0000'
			}
		}, {
			title : 'Example 1',
			inline : 'span',
			classes : 'example1'
		}, {
			title : 'Example 2',
			inline : 'span',
			classes : 'example2'
		}, {
			title : 'Table styles'
		}, {
			title : 'Table row 1',
			selector : 'tr',
			classes : 'tablerow1'
		} ],
		spellchecker_callback : function( method, text, success, failure ){
			tinymce.util.JSONRequest.sendRPC( {
				url : args.mce_spellcheck_url,
				method : "spellcheck",
				params : {
					lang : this.getLanguage(),
					words : text.match( this.getWordCharPattern() )
				},
				success : function( result ){
					success( result );
				},
				error : function( error, xhr ){
					failure( "Spellcheck Error : " + xhr.status );
				}
			} );
		},
		convert_urls : false
	} );


}
